body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.isitporn-button {
  font-family: VAL;
  font-weight: bold;
  line-height: 1em;
  font-size: 3em;
  color: white;
  cursor: pointer;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
.isitporn-button:hover, .isitporn-button:active {
  color: #DDD;
  text-decoration: none;
}

.isitporn-bg {
  background-color: #e67c84 !important;
  background-image: repeating-linear-gradient(
    120deg
    , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1) 1px, transparent 1px, transparent 60px), repeating-linear-gradient(
    60deg
    , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1) 1px, transparent 1px, transparent 60px), linear-gradient(
    60deg
    , rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0.1)), linear-gradient(
    120deg
    , rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0.1));
  background-size: 70px 120px;
}

.impact-text {
  font-family: Impact;
  background: none;
  border: none;
  font-weight: bold;
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;

  color: #FFFFFF;
  text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px;
}
